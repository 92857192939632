<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <template
            v-if="formattedItem.prospecto"
          >
            <v-chip
              label
              small
              color="blue"
              dark
              class="mt-1 ml-1"
            >
              Prospecto
            </v-chip>
          </template>
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-fab-button
        v-if="hasEditPerm(permissions.clientePotencial.id)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ClientePotencialViewData'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import { ItemNotFoundError, TABLA } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        addPresupuesto: {
          title: 'Presupuesto',
          visible: true,
          icon: 'add'
        },
        addEstudioFinanciero: {
          title: 'Estudio financiero',
          visible: true,
          icon: 'add'
        },
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
      },
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente_potencial
        item.title = this.$online.clientePotencial.title(item)
        item.subtitle = this.$online.clientePotencial.subtitle(item)
        item.alerts = []
        if (this.hasEditPerm(this.permissions.clientePotencialDireccion.id) && (!item.latitud || !item.longitud)) {
          item.alerts.push({
            value: 'Cliente potencial sin geolocalización',
            type: 'warning',
            options: {
              persistent: true,
              actions: [{ text: 'Capturar', flat: true, handler: this.geolocalizar }],
            },
          })
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.nombre
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = {
        clientePotencial: {
          title: 'Cliente potencial',
          rows: [
            'idcliente_potencial',
            'nombre',
            'razon_social',
            'cif',
            { name: 'fcreacion', filter: this.$options.filters.shortDateTime },
            { name: 'nombre_representante_principal', label: 'Representante principal' },
            {
              name: 'iban_principal',
              label: 'IBAN principal',
              password: true,
              clickable: true,
              icon: this.$vuetify.icons.values.show,
              visible: this.hasViewPerm(this.permissions.clientePotencialCuenta.id)
            },
          ],
        },
        comerciales: {
          title: 'Datos comerciales',
          rows: [
            'tcliente_descripcion',
            'tactividad_descripcion',
            'canal_conocimiento_descripcion',
            'agente_nombre',
            'comercial_nombre',
          ]
        },
        direcciones: {
          title: 'Direcciones',
          rows: [
            { name: 'cliente_direccion', label: 'Dirección fiscal' },
            { name: 'cliente_direccion_postal', label: 'Dirección postal' },
            { name: 'cliente_direccion_servicio', label: 'Dirección de servicio' },
          ],
        },
        otros: {
          title: 'Otros datos',
          rows: [
            'idioma_descripcion',
            'pais_descripcion',
            'empresa_nombre',
          ]
        },
        comentarios: {
          title: 'Comentarios',
          rows: [
            { name: 'observaciones', filter: this.$options.filters.linebreaksBr },
          ]
        }
      }
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.clientePotencialDireccion.id)
      this.toolbarOptions.addPresupuesto.visible = this.hasInsertPerm(this.permissions.presupuestocli.id)
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectClientePotencial(this, this.routeParams.idcliente_potencial)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.map.visible = !!this.item.dataset.latitud && !!this.item.dataset.longitud
        } else {
          const error = new ItemNotFoundError()
          error.extra = {
            title: 'Cliente potencial no encontrado',
            message: `El cliente potencial que intentas visualizar no existe. Puede haber sido eliminado o convertido a un cliente real.`,
            redirectRoute: { name: 'clientespotenciales__cliente-potencial-list' }
          }
          throw error
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(this,
        this.routeParams.idcliente_potencial,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // presupuestos
      if (this.hasViewPerm(this.permissions.presupuestocli.id)) {
        const detailPresupuestos = this.addDetail(
          'presupuestos', 'Presupuestos', 'Presupuestos del cliente potencial', 'presupuestos'
        )
        detailPresupuestos.badge = resp.data.selectPresupuestoCount.result.dataset[0].count || 0
      }
      // contactos
      if (this.hasViewPerm(this.permissions.clientePotencialTelefono.id)) {
        const detailContactos = this.addDetail(
          'contactos', 'Personas de contacto', 'Personas de contacto del cliente potencial', 'personaContacto'
        )
        detailContactos.badge = resp.data.selectPersonasContactoCount.result.dataset[0].count || 0
      }
      // ficheros
      if (this.hasViewPerm(this.permissions.clientePotencialAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // cuentas bancarias
      if (this.hasViewPerm(this.permissions.clientePotencialCuenta.id)) {
        const detailClienteCuenta = this.addDetail(
          'clienteCuenta', 'Cuentas bancarias', 'Cuentas bancarias del cliente potencial', 'clienteCuenta'
        )
        detailClienteCuenta.badge = resp.data.selectClienteCuentaCount.result.dataset[0].count || 0
      }
      // representante
      if (this.hasViewPerm(this.permissions.clientePotencialRepresentante.id)) {
        const detailRepresentante = this.addDetail(
          'representantes', 'Representantes', 'Representantes del cliente potencial', 'representantes'
        )
        detailRepresentante.badge = resp.data.selectRepresentanteCount.result.dataset[0].count || 0
      }
      // direcciones
      if (this.hasViewPerm(this.permissions.clientePotencialDireccion.id)) {
        const detailDirecciones = this.addDetail(
          'direcciones', 'Direcciones', 'Postal, fiscal o de servicio', 'direcciones'
        )
        detailDirecciones.badge = resp.data.selectClienteDireccionCount.result.dataset[0].count || 0
      }
      // estudio finacniero
      if (this.hasViewPerm(this.permissions.clientePotencialEstudioFinanciero.id)) {
        const detailEstudioFinanciero = this.addDetail(
          'estudiosFinancieros', 'Estudios financieros', 'Estudios financieros del cliente potencial', 'estudioFinanciero'
        )
        detailEstudioFinanciero.badge = resp.data.selectEstudioFinancieroCount.result.dataset[0].count || 0
      }
      // operaciones
      if (this.hasViewPerm(this.permissions.operaciones.id)) {
        const detailOperacionCRM = this.addDetail(
          'operacionCRM', 'Operaciones', 'Operaciones relacionadas con el cliente potencial', 'operacionCRM'
        )
        detailOperacionCRM.badge = resp.data.selectOperacioncrmCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'telefonos__clientes-potenciales-cliente-telefono-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__clientes-potenciales-fichero-list',
          params: {
            id: this.routeParams.idcliente_potencial,
            idtabla: TABLA.cliente_potencial.idtabla,
          },
        })
      } else if (data.detail.name === 'clienteCuenta') {
        this.$appRouter.push({
          name: 'cuentasbancarias__clientes-potenciales-cliente-cuenta-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'representantes') {
        this.$appRouter.push({
          name: 'representantes__clientes-potenciales-representante-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'direcciones') {
        this.$appRouter.push({
          name: 'direcciones__clientes-potenciales-cliente-direccion-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'estudiosFinancieros') {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'presupuestos') {
        this.$appRouter.push({
          name: 'presupuestos__clientes-potenciales-presupuestocli-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__clientes-potenciales-operacioncrm-list',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.addPresupuesto) {
        this.$appRouter.push({
          name: 'presupuestos__clientes-potenciales-presupuestocli-add',
          params: {
            idclientePotencial: this.routeParams.idcliente_potencial,
          },
        })
      } else if (option === this.toolbarOptions.addEstudioFinanciero) {
        this.$appRouter.push({
          name: 'estudiosfinancieros__clientes-potenciales-estudio-financiero-add',
          params: {
            idcliente_potencial: this.routeParams.idcliente_potencial,
          },
        })
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del cliente potencial "${this.item.dataset.cliente_direccion}"?`)
        if (res) {
          await Data.geolocalizar(
            this,
            this.item.dataset.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: 'clientespotenciales__cliente-potencial-edit',
        params: {
          idcliente_potencial: this.item.dataset.idcliente_potencial,
        },
      })
    },
    async clickSummaryRow (row) {
      if (row.icon === this.$vuetify.icons.values.show) {
        this.$loading.showManual('Auditando acceso...')
        try {
          await this.$online.auditoria.auditarViewRecordClienteCuenta(this.item.dataset.idcliente_cuenta_principal)
        } finally {
          this.$loading.hide()
        }
        this.setMoreInfoRowValue(row.name, 'password', false, 'clientePotencial')
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.hide, 'clientePotencial')
      } else {
        this.setMoreInfoRowValue(row.name, 'password', true, 'clientePotencial')
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.show, 'clientePotencial')
      }
    },
  },
}
</script>
